export default {
  colors: {
    primary: '#8257E6',
    background: '#FFFFFF',
    shape: `#F2F2FA`,
    title: `#3D3D4D`,
    text: `#6C6C80`,
    components: {
      blockquote: {
        background: `#8257E6`,
        text: `#FFF`,
      },
    },
  },
};
